<template>
  <b-form ref="leadForm" class="mb-4" @submit.prevent="onSubmit()">
    <LeadsHeader />
    <b-alert v-if="lead.id" show>
      <b-icon-info-circle></b-icon-info-circle> Editing lead data for 
      <b><router-link v-b-tooltip.hover.top="'View Details'" :to="{ name: 'LeadDetails', params: { id: lead.id } }">
        {{ `${$store.getters.lead.fname} ${$store.getters.lead.lname}` }}
      </router-link></b>
    </b-alert>
    <b-form-row>
      <b-col cols="12" md="6">
        <fieldset v-if="!$store.getters.currentFranchise.id">
          <legend>Franchise</legend>
          <b-form-group
            :label="$t('label.franchise')"
            label-sr-only
          >
            <BaseFormFranchise @selected-code="lead.franchiseCode = $event" :value="lead.franchiseCode" no-label />
            <!-- BaseFormSelectFranchise v-model="lead.franchiseCode" / -->
          </b-form-group>
        </fieldset>

        <p v-else>Franchise Code: {{ lead.franchiseCode }}</p>
        
        <fieldset>
          <legend>Status</legend>
          <b-form-row  align-v="center">
            <b-col cols="12" md="6">
              <b-form-group
                label="Status"
                label-cols-sm="3"
              >
                <b-select
                  id="status"
                  :options="leadStatusOptions"
                  v-model="lead.status"
                  text-field="status"
                  value-field="@id"
                  required
                >
                  <template #first>
                    <b-select-option :value="null">{{ $t('label.status') }}</b-select-option> 
                  </template>
                </b-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="text-center mb-2">
              <b-form-checkbox
                v-model="lead.managed"
                id="managed"
                style="margin-top: -2px"
              >{{ $t('lead.sscManaged') }}</b-form-checkbox>
            </b-col>
          </b-form-row>
        </fieldset>
        <fieldset>
          <legend>Contact</legend>
          <b-form-row>
            <b-col cols="12" lg="6">
              <b-form-group
                :label="$t('label.firstName')"
                label-cols-sm="3"
                class="required"
              >
                <b-form-input
                  v-model="lead.fname"
                  id="fname"
                  size="10"
                  maxlength="35"
                  required="required"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                :label="$t('label.lastName')"
                label-cols-sm="3"
                class="required"
              >
                <b-form-input
                  v-model="lead.lname"
                  id="lname"
                  size="10"
                  maxlength="35"
                  required="required"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" lg="6">
              <b-form-group
                :label="$t('label.email')"
                label-cols-sm="3"
              >
                <b-form-input
                   v-if="!existing.email"
                  type="email"
                  v-model="lead.email"
                  maxlength="250"
                  id="email"
                  @blur="checkEmail"
                  :formatter="val => (val.replace(/[^0-9a-z-\.\@]/i, ''))"
                  trim
                ></b-form-input>
                <b-button-group v-else class="mt-1">
                  <b-button
                    size="sm"
                    variant="warning"
                    class="text-white"
                    :to="{ name: 'LeadForm', params: { id: existing.email } }"
                  >Edit Existing Lead</b-button>
                  <b-button
                    size="sm"
                    variant="success"
                    class="text-white"
                    @click="existing.email=null"
                  >Update Email</b-button>
                </b-button-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                :label="$t('label.phone')"
                label-cols-sm="3"
              >
                <BaseFormPhone v-model="lead.phone" />
              </b-form-group>
            </b-col>
          </b-form-row>
        </fieldset>
        <fieldset>
          <legend>Student</legend>
          <b-form-row>
            <b-col cols="12" lg="6">
              <b-form-group
                :label="$t('label.firstName')"
                label-cols-sm="3"
                class="required"
              >
                <b-form-input
                  v-model="lead.clientFname"
                  id="clientFname"
                  size="10"
                  maxlength="35"
                  required="required"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                :label="$t('label.lastName')"
                label-cols-sm="3"
                class="required"
              >
                <b-form-input
                  v-model="lead.clientLname"
                  id="clientLname"
                  size="10"
                  maxlength="35"
                  required="required"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
        </fieldset>
        <fieldset>
          <legend>{{ $t('drip.campaign') }}</legend>
          <b-form-group
            :label="$t('drip.campaign')"
            label-sr-only
          >
            <b-select
              v-if="!lead.id"
              id="campaign_id"
              :options="dripCampaignOptions"
              text-field="title"
              value-field="@id"
              v-model="campaign"
            >
              <template #first>
                <b-select-option :value="null">No Campaign Selected</b-select-option>
              </template>
            </b-select>
            <p v-else>To edit campaign options, see the <router-link :to="{ name: 'LeadDetails', params: { id: lead.id } }">Details</router-link> page.</p>
          </b-form-group>
        </fieldset>
      </b-col>
      <b-col cols="12" md="6">
        <fieldset>
          <legend>Source</legend>
          <b-form-group
            :label="$t('lead.source')"
            label-sr-only
          >
            <b-form-input
              v-model="lead.refSource"
              id="ref_source"
              placeholder="Where did the lead originate from?"
              trim
            ></b-form-input>
          </b-form-group>
        </fieldset>
        <fieldset>
          <legend>{{ $t('lead.requirements') }}</legend>
          <b-form-group
              :label="$t('lead.requirements')"
            label-sr-only
          ><b-form-textarea
              cols="40"
              rows="10"
              id="message"
              v-model="lead.message"
              placeholder="Why did the lead contact us?"
              style="height: 100px"
            ></b-form-textarea>
          </b-form-group>
        </fieldset>
        <fieldset>
          <legend>Address</legend>
          <b-form-row>
            <b-col cols="12" lg="7">
              <b-form-group
                :label="$t('label.country')"
                label-cols-sm="3"
              >
                <BaseFormSelectCountry v-model="lead.country" />
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-group
                :label="$t('label.zip')"
                label-cols-sm="3"
                class="required"
              >
                <BaseFormPostalCode id="zip_code" v-model="lead.zipCode" :required="true" @pc-data="(e) => {lead.city = e.city; lead.state = e.state}" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" lg="7">
              <b-form-group
                :label="$t('label.address')"
                label-cols-sm="3"
              >
                <b-form-input
                  v-model="lead.street1"
                  id="street1"
                  maxlength="150"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-group
                :label="$t('label.address2')"
                label-cols-sm="3"
              >
                <b-form-input
                  class="street2"
                  v-model="lead.street2"
                  id="street2"
                  size="10"
                  maxlength="35"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" lg="7">
              <b-form-group
                :label="$t('label.city')"
                label-cols-sm="3"
              >
                <b-form-input
                  v-model="lead.city"
                  id="city"
                  size="10"
                  maxlength="35"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="5">
              <b-form-group
                :label="$t('label.state')"
                label-cols-sm="3"
              >
                <BaseFormSelectState :country="lead.country" v-model="lead.state" />
              </b-form-group>
            </b-col>
          </b-form-row>
        </fieldset>
      </b-col>
    </b-form-row>
    <BaseFormButtons size="md" @form-button-submit="onSubmit()" no-cancel no-reset block />
  </b-form>
</template>

<script>
import LeadsHeader from '@/views/leads/LeadsHeader'
import { constants } from '@/shared/constants'
import { mapActions, mapGetters } from 'vuex'

const INI_STATUS = 'New'

export default {
  name: 'LeadForm',
  components: { LeadsHeader },
  data () {
    return {
      lead: {
        clientFname: null,
        clientLname: null,
        city: null,
        country: 'US',
        email: null,
        fname: null,
        franchiseCode: null,
        id: null,
        lname: null,
        message: null,
        phone: null,
        state: null,
        status: null,
        refSource: null,
        street1: null,
        street2: null,
        zipCode: null
      },
      campaignOpts: [],
      campaign: null,
      existing: {
        email: null
      }
    }
  },
  computed: {
    ...mapGetters(['franchise', 'leads', 'leadStatusOptions', 'dripCampaignOptions']),
    iniStatus () {
       const iniStatus = this.leadStatusOptions.find(e => e.status === INI_STATUS)
       return iniStatus['@id']
    },
    iniManaged () {
      // @TODO base on user / franchise status
      return (this.franchise.level === 3)
    }
  },
  methods: {
    ...mapActions(['getFranchise', 'resetFranchise', 'getLeadStatuses', 'getDripCampaigns', 'getLead', 'getLeads', 'postLead', 'resetLead', 'putLead']),
    async checkEmail () {
      if (!this.lead.email || this.lead.id) return
      await this.getLeads({
        email: this.lead.email
      })
      if (this.leads.length) {
        const e = 'A lead with that email address already exists!'
        this.existing.email = this.leads[0].id
        this.lead.email = null
        this.toastError(e)
      }
    },
    validate () {
      // @TODO
    },
    async onSubmit () {
      try {
        this.validate()
        if (!this.lead.id) {
          await this.postLead(this.lead)
          this.lead.id = this.$store.getters.lead.id
        } else {
          await this.putLead(this.lead)
        }
        this.toastSuccess(this.$t('lead.leadSaved'))
      } catch (e) {
        this.toastError(e)
        console.error('LeadForm::onSubmit()', e)
      }
    },
    async doGetDripCampaigns () {
      if (this.lead.franchiseCode) {
        await this.getDripCampaigns({
          franchiseCode: this.lead.franchiseCode
        })
      }
      if (!this.dripCampaignOptions.length) {
        await this.getDripCampaigns({
          franchiseCode: constants.CORPORATE_FRANCHISE_CODE
        })
      }
    }
  },
  watch: {
    async 'lead.franchiseCode' (val) {
      await this.getFranchise(val)
      this.doGetDripCampaigns()
      this.lead.managed = (this.franchise.level === 3)
    }
  },
  async mounted () {
    await this.getLeadStatuses()
    this.lead.status = this.iniStatus
    if (this.$route.params.id) {
      await this.getLead(this.$route.params.id)
      const lead = { ...this.$store.getters.lead }
      lead.status = lead.status['@id'] || lead.status
      lead.country = lead.country || 'US'
      for (const k in this.lead) {
        this.lead[k] = lead[k] || null
      }
    }
    this.doGetDripCampaigns()
  },
  destroyed () {
    this.resetLead()
    this.resetFranchise()
  }
}
</script>
